/* src/components/Typewriter.css */
.typewriter {
  display: inline; /* Ensures inline flow with surrounding text */
  border-right: 2px solid #34495E; /* Matches the surrounding span color */
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom; /* Adjust as needed for alignment */
  animation: blink-caret 0.75s step-end infinite;
  font-family: inherit; /* Inherits font properties from parent */
  font-size: inherit;
  line-height: inherit;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #34495E;
  }
}
