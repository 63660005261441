/* src/pages/LandingPage.css */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-y: auto;
  scroll-snap-type: y mandatory; /* Snap vertically to sections */
}

.threeBackgroundWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; 
  pointer-events: auto; /* Let the background be clickable */
}

/* 2) The main content is visually on top, but pass pointer events through by default. */
.contentWrapper {
  position: relative;
  z-index: 1;
  pointer-events: none; /* Transparent to pointer events by default */
}

/* 3) Re-enable pointer events for actual interactive elements. */
.contentWrapper button,
.contentWrapper a,
.contentWrapper input,
.contentWrapper select,
.contentWrapper textarea,
.contentWrapper .actionButton {
  pointer-events: auto; /* These are clickable / hoverable */
}


.heroSection,
.servicesSection,
.contactSection {
  width: 100%;
  min-height: 100vh; /* Each section takes up full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  z-index: 3
}
.landingContainer {
  width: 100%;
  min-height: 100vh; /* Each section takes up the full viewport height */
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  z-index: 2;
}

/* Main Logo Styles */
.heroLogo {
  width: 100%;
  height: auto;
  transition: opacity 2s ease-in-out, transform 0.3s ease;
  opacity: 0;
  z-index: 3
}

.heroLogo.active {
  opacity: 1;
  animation: fadeIn 2s forwards;
  z-index: 3
}

/* Glow Logo Styles */
.logoGlow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: glowFade 4s forwards;
  z-index: 3
}

@keyframes glowFade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}

/* Mission Statement and Buttons Fade-In */
/* Wrapper for Content Fade-In */
.contentFadeInWrapper {
  width: 100%;
  height: 300px; /* Reserve space */
  margin-top: 0px; /* Space between the logo and content */
  position: relative;
  display: flex; /* Enable flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: baseline; /* Center vertically */
  overflow: hidden; /* Preventscontent overflow during animations */
}

/* Content Fade-In */
.contentFadeIn {
  opacity: 0;
  visibility: hidden; /* Prevents content from showing initially */
  animation: fadeInContent 1s forwards;
  text-align: center;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move into position */
    visibility: visible;
  }
}


/* Logo Container Styles */

.logoContainer {
  position: relative;
  width: 25vw;
  max-width: 700px;
  height: auto;
  margin-bottom: 0px; /* Fixed spacing below the logo */
  z-index: 3;
  text-align: center;
}

/* Hero Section */
.heroSection {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 100px;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}

/* Mission Statement Text */
.missionText {
  color: #ffffff;
  font-size: 1.75rem;
  margin-bottom: 20px;
  z-index: 3;
 }

/* Action Buttons Styles */
.actionButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px; /* Space between text and buttons */
  z-index: 3;
}

.actionButton {
  background: rgba(255, 255, 255, 0.15); /* semi-transparent white */
  color: #f0f0f0;
  padding: 12px 24px; /* Adjust for desired size */
  border-radius: 16px; 
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase; 
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1.5rem; /* Add spacing above the button */
  display: inline-block; /* or inline-flex if you want icon + text horizontally */
}

.actionButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(31, 38, 135, 0.37);
  background: rgba(255, 255, 255, 0.25);
  font-weight: 600;
}

.actionButton:active {
  transform: translateY(-2px);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

/* Flash Overlay for Navy Blue Nodes */

@keyframes flashAnimation {
  0% {
    background: transparent;
  }
  25% {
    background: rgba(52, 73, 94, 0.1); /* Navy Blue with low opacity */
  }
  50% {
    background: transparent;
  }
  75% {
    background: rgba(52, 73, 94, 0.1);
  }
  100% {
    background: transparent;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .logoContainer {
    width: 70vw; /* Adjust as needed */
    max-width: 250px; /* Prevents logos from being too large */
  }

  .missionText {
    font-size: 1.2rem;
  }

  .actionButtons {
    gap: 10px;
  }

  .actionButton {
    padding: 16px 28px; /* Adjusted for smaller screens */
    font-size: 1rem;
  }

  .contentFadeIn {
    animation: fadeInContent 3s forwards;
  }
}

@media (max-width: 480px) {
  .logoContainer {
    width: 70vw; /* Adjust as needed for smaller screens */
    max-width: 250px;
  }

  .missionText {
    font-size: 1rem;
  }

  .actionButtons {
    gap: 8px;
  }

  .actionButton {
    padding: 7px 14px; /* Adjusted for smaller screens */
    font-size: 0.9rem;
  }
}

/* Additional overrides for contentFadeIn */
.contentFadeIn > h1 {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .contentFadeIn > h1 {
    font-size: 18px; /* Adjust the font size as needed for smaller screens */
  }
}

@media (max-width: 480px) {
  .contentFadeIn > h1 {
    font-size: 16px; /* Further adjust for very small screens */
  }
}


.contentFadeIn {
  max-width: 50vw; /* Default for desktops and larger screens */
  width: 100%; /* Ensure it takes full width on smaller screens */
}

@media (max-width: 768px) {
  .contentFadeIn {
    max-width: 90vw; /* Adjust max-width for smaller screens */
  }
}

/* Modal Styles */

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Styles */
.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.15);
  color: #f0f0f0;
  padding: 40px; /* Use larger padding as default */
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .Modal {
    padding: 16px; /* Reduce padding for very small screens */
  }
}

/* Modal Overlay */
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Darker semi-transparent black overlay */
  z-index: 999; /* Behind the modal */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Header */
.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

/* Modal Logo */
.modalLogo {
  width: 80px; /* Adjust size as needed */
  height: auto;
}

/* Modal Heading */
.Modal h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

/* Modal Form */
.Modal form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Modal input[type="password"] {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1); /* Slightly translucent input background */
  color: #f0f0f0;
  outline: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.Modal input[type="password"]:focus {
  box-shadow: 0 0 8px rgba(31, 38, 135, 0.37);
  border-color: rgba(31, 38, 135, 0.6);
}

/* Submit Button */
.Modal button[type="submit"] {
  background-color: #464646;
  color: #fff;
  padding: 12px 24px;
  border-radius: 16px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.Modal button[type="submit"]:hover {
  background-color: #464646;
}

/* Error Message */
.Modal .error {
  color: #9f1414;
  font-size: 0.9rem;
  margin-top: 8px; /* Add spacing above the error message */
  text-align: center; /* Center the error message */
  display: block; /* Prevent overlap */
  text-transform: uppercase;
}

/* Close Button */
.closeButton {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #464646;
  color: #fff;
  border: none;
  border-radius: 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: #c0392b;
}

.closeButton:active {
  background-color: #a93226;
}

.servicesSection, .contactSection {
  z-index: 1 !important;
  position: relative;
}

.servicesSection h2,
.contactSection h2 {
  width: 30%; /* Set width to 30% of the container */
  margin: 0 auto; /* Center the header */
  text-align: center;
  padding-bottom: 1rem;
  font-size: 2.5rem; /* Adjust font size */
  font-weight: 700; /* Make the text bold */
  color: #ffffff; /* Text color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(31, 38, 135, 0.6),
               0 0 30px rgba(31, 38, 135, 0.8);
  animation: glowEffect 2s infinite alternate ease-in-out;
}

/* Glowing Effect Animation */
@keyframes glowEffect {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.273),
                 0 0 20px rgba(31, 38, 135, 0.345),
                 0 0 30px rgba(31, 38, 135, 0.252);
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.599),
                 0 0 30px rgba(42, 46, 99, 0.633),
                 0 0 40px rgba(81, 87, 169, 0.62);
  }
}

/* Responsive Styling for Headers */
@media (max-width: 768px) {
  .servicesSection h2,
  .contactSection h2 {
    width: 50%; /* Adjust width for smaller screens */
    font-size: 1rem; /* Reduce font size */
  }
}

@media (max-width: 480px) {
  .servicesSection h2,
  .contactSection h2 {
    width: 70%; /* Adjust width for very small screens */
    font-size: 1.8rem;
  }
}


.navButton {
  background: rgba(255, 255, 255, 0.15); /* semi-transparent white */
  color: #f0f0f0;
  padding: 12px 24px; /* Adjust for desired size */
  border-radius: 16px; 
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase; 
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1.5rem; /* Add spacing above the button */
  display: inline-block; /* or inline-flex if you want icon + text horizontally */
}

.navButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(31, 38, 135, 0.37);
  background: rgba(255, 255, 255, 0.25);
  font-weight: 600;
}

.navButton:active {
  transform: translateY(-2px);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

@media (max-width: 768px) {
  .navButton {
    padding: 16px 28px; /* Adjusted for smaller screens */
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .navButton {
    padding: 7px 14px; /* Adjusted for smaller screens */
    font-size: 0.9rem;
  }
}

@font-face {
  font-family: 'Qaranta';
  src: url('../assets/fonts/Qaranta Bold.woff2') format('woff2'),
       url('../assets/fonts/Qaranta Bold.woff') format('woff'),
       url('../assets/fonts/Qaranta Bold.otf') format('opentype');
}


body {
  font-family: 'Qaranta', Arial, sans-serif; /* Set as default font */
}
