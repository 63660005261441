/* Global box-sizing for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: #1a1a1a;
  color: #ffffff;
}

/* Contact Section */
.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 16px;
  text-align: center;
  min-height: 100vh;
}

/* Contact Form */
.contactForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Form Group */
.formGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formGroup label {
  font-size: 1rem;
  font-weight: 500;
  color: #e0e0e0;
  text-align: left;
}

.formGroup input,
.formGroup textarea,
.formGroup select,
.react-tel-input .form-control {
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.formGroup input:focus,
.formGroup textarea:focus,
.formGroup select:focus,
.react-tel-input .form-control:focus {
  border-color: #009ffd;
  box-shadow: 0 0 8px rgba(0, 159, 253, 0.5);
}

/* Phone Input Container */
.react-tel-input {
  width: 100%;
}

.react-tel-input .form-control {
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
}

.react-tel-input .form-control:focus {
  outline: none;
}

.react-tel-input .flag-dropdown {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px 0 0 8px;
}

.react-tel-input .selected-flag {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px 0 0 12px;
}

/* Error Message Styling */
.error {
  font-size: 0.9rem;
  color: #ff4d4d;
  margin-top: 4px;
  text-align: left;
}

/* Buttons */
.buttonGroup {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.stepButton {
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.stepButton:hover {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 12px 40px rgba(31, 38, 135, 0.5);
}

.stepButton:disabled {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}
/* Phone Input Container */
.react-tel-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative; /* Ensure elements are relative to this container */
}

.react-tel-input .form-control {
  flex: 1;
  border-radius: 0 12px 12px 0;
  border: none;
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
  font-size: 1rem;
  padding-left: 10px; /* Ensure text doesn't overlap the dropdown */
}

.react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px;
  background: rgba(255, 255, 255, 0.08);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px; /* Adjust padding for better alignment */
  position: relative; /* Keep dropdown aligned with input */
  z-index: 1; /* Ensure it stays above the input field */
}

.react-tel-input .selected-flag {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px 0 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tel-input .form-control:focus {
  outline: none;
  border-color: #009ffd;
  box-shadow: 0 0 8px rgba(0, 159, 253, 0.5);
}

.react-tel-input .flag-dropdown:focus {
  outline: none;
  box-shadow: none;
}


/* Social Links Container */
.formGroup {
  gap: 12px;
}

/* Scrollable Social Links Section */
/* Scrollable Social Links Section */
.scrollableSocialLinks {
  max-height: 200px; /* Limit the height of the social links container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 8px; /* Add padding for better usability */
  z-index: 5; /* Ensure it's above other interactive elements */
  position: relative; /* Position it explicitly in the stacking context */
  
  pointer-events: auto; /* Ensure interactivity */
  border-radius: 8px; /* Optional: Rounded edges for better visuals */
}

/* Ensure Scrollbar Visibility */
.scrollableSocialLinks::-webkit-scrollbar {
  width: 8px;
}

.scrollableSocialLinks::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.scrollableSocialLinks::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}

.scrollableSocialLinks::-webkit-scrollbar-track {
  background: transparent;
}

/* Position Fix for Social Row */
.socialRow {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  z-index: 6; /* Explicitly above background */
}

/* Overlay for Conflicting Elements */
.scrollOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none; /* Ensure no conflicts */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .socialRow {
    flex-direction: column;
    gap: 8px;
  }

  .socialRow select,
  .socialRow input {
    width: 100%;
  }

  .socialRow button {
    align-self: flex-end;
  }
}

/* Inputs and Selects */
.socialRow select,
.socialRow input {
  flex: 1;
  padding: 8px;
  font-size: 0.9rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.socialRow select:focus,
.socialRow input:focus {
  border-color: #009ffd;
  box-shadow: 0 0 8px rgba(0, 159, 253, 0.5);
}

/* Remove Button */
.socialRow button {
  background: #e74c3c;
  color: #ffffff;
  padding: 6px 12px;
  font-size: 0.8rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.socialRow button:hover {
  background: #c0392b;
}

.socialRow button:disabled {
  background: rgba(231, 76, 60, 0.5);
  cursor: not-allowed;
}

/* Add More Button */
.addMoreButtonContainer {
  text-align: right;
  margin-top: 8px;
}

.addMoreButton {
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  padding: 8px 16px;
  font-size: 0.9rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.addMoreButton:hover {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .socialRow {
    flex-direction: column;
    gap: 8px;
  }

  .socialRow select,
  .socialRow input {
    width: 100%;
  }

  .socialRow button {
    align-self: flex-end;
    margin-bottom: 10px;
  }
}

/* Contact.css */

.thankYouMessage {
  text-align: center;
  padding: 2rem;
  background-color: transparent;
  border-radius: 8px;
}

.thankYouMessage h3 {
  color: #fff;
  margin-bottom: 1rem;
}

.thankYouMessage p {
  color: #e0e0e0;
  font-size: 1rem;
}
