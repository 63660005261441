/* src/pages/MainPage.css */

/* Import Modern Font (Roboto) */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* CSS Variables for Consistent Styling */
.mainContainer {
  --color-background: #000000; /* Black */
  --color-primary: #FFFFFF;     /* White */
  --color-secondary: #1A1A2E;   /* Navy */
  --color-accent: #C0C0C0;      /* Metallic Silver */
  --color-muted: #2F4F4F;       /* Gunmetal Gray */
  --color-hover: #34495E;       /* Dark Navy for hover effects */

  --font-family: 'Roboto', sans-serif;

  /* Shadows and Glows */
  --shadow-light: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-deep: 0 8px 12px rgba(0, 0, 0, 0.2);
  --glow: 0 0 8px rgba(52, 73, 94, 0.6);
}

/* Reset some default styles for consistency within mainContainer */
.mainContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styles */
.mainContainer {
  font-family: var(--font-family);
  background-color: var(--color-background);
  color: var(--color-primary);
  line-height: 1.6;
}

/* Main Container */
.mainContainer {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  /* Removed scroll-snap-type for natural scrolling */
}

/* Vanta Canvas Adjustment */
.mainContainer canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -1; /* Ensure canvas is behind all content */
}

/* Section Styles */
.mainContainer .section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px; /* Reduced padding for minimalism */
  box-sizing: border-box;
  /* Removed scroll-snap-align for natural scrolling */
  transition: background-color 0.5s ease;
}

/* Specific Section Backgrounds with Subtle Gradients */
.mainContainer .consultancySection {
  background: transparent;
}

.mainContainer .linesOfBusiness {
  background: transparent;
}

.mainContainer .contactSection {
  background: transparent; /* Solid color can be applied here if needed */
}

/* Content Inner Wrapper */
.mainContainer .contentInner {
  max-width: 800px; /* Reduced max-width for better readability */
  width: 100%;
  text-align: center;
  color: var(--color-primary);
  padding: 20px;
  position: relative; /* Ensure stacking context */
  z-index: 1; /* Ensure content is above Vanta background */
}

/* Hero Logo */
.mainContainer .heroLogo {
  width: 20vw; /* Reduced from 555px for better responsiveness */
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.mainContainer .heroLogo:hover {
  transform: scale(1.05);
  /* Removed box-shadow as per request */
}

/* Brand Name Styling */
.mainContainer .brandName { /* Further reduced from 2rem */
  color: var(--color-primary);
}

/* Headings */
.mainContainer .contentInner h1,
.mainContainer .contentInner h2,
.mainContainer .contentInner h3 {
  margin-bottom: 12px; /* Reduced margin */
  font-weight: 500;
  color: var(--color-primary);
}

.mainContainer .contentInner h1 {
  font-size: 2rem; /* Further reduced from 2.5rem */
  line-height: 1.3;
  position: relative;
}

.mainContainer .contentInner h2 {
  font-size: 2rem; /* Reduced from 2.5rem */
  margin-bottom: 20px;
}

.mainContainer .contentInner h3 {
  font-size: 1.3rem; /* Reduced from 1.5rem */
  margin-bottom: 12px;
}

/* Paragraphs */
.mainContainer .contentInner p {
  margin-bottom: 16px; /* Reduced margin */
  font-size: 1rem; /* Reduced from 1.2rem */
  color: var(--color-muted);
}

/* Forms */
.mainContainer .contentInner form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 80vw; /* Reduced max-width */
  margin-top: 25px; /* Reduced margin */
}

.mainContainer .contentInner label {
  width: 100%;
  margin-bottom: 15px; /* Reduced margin */
  font-size: 0.95rem; /* Slightly reduced */
  color: var(--color-primary);
  text-align: left;
}

.mainContainer .contentInner input,
.mainContainer .contentInner textarea {
  width: 100%;
  padding: 12px 16px; /* Reduced padding */
  margin-top: 6px; /* Reduced margin */
  border: 1px solid var(--color-muted);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-primary);
  font-size: 0.95rem; /* Slightly reduced */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.mainContainer .contentInner input::placeholder,
.mainContainer .contentInner textarea::placeholder {
  color: #777777;
}

.mainContainer .contentInner input:focus,
.mainContainer .contentInner textarea:focus {
  outline: none;
  border-color: var(--color-hover);
  box-shadow: 0 0 8px var(--color-hover); /* Slightly reduced shadow */
}

/* Submit Button */
.mainContainer .contentInner button[type="submit"] {
  padding: 8px 16px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly reduced */
  cursor: pointer;
  background: linear-gradient(45deg, var(--color-primary), var(--color-accent));
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-light);
}

.mainContainer .contentInner button[type="submit"]:hover {
  background: linear-gradient(45deg, var(--color-accent), var(--color-primary));
  transform: translateY(-2px); /* Slightly reduced */
  box-shadow: var(--shadow-deep);
}

.mainContainer .contentInner button[type="submit"]:active {
  transform: translateY(0);
  box-shadow: var(--shadow-light);
}

/* Action Buttons Styles */
.mainContainer .actionButtons {
  display: flex;
  justify-content: center;
  gap: 16px; /* Reduced gap */
  flex-wrap: wrap;
  margin-top: 30px; /* Reduced margin */
}

.mainContainer .actionButtons .actionButton {
  padding: 10px 20px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly reduced */
  cursor: pointer;
  background: var(--color-secondary);
  color: var(--color-primary);
  border: 2px solid var(--color-accent);
  border-radius: 4px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-light);
}

.mainContainer .actionButtons .actionButton:hover {
  background: var(--color-muted);
  transform: translateY(-2px); /* Slightly reduced */
  box-shadow: var(--shadow-deep);
}

.mainContainer .actionButtons .actionButton:active {
  transform: translateY(0);
  box-shadow: var(--shadow-light);
}

/* Navigation Buttons Styles */
.mainContainer .navigationButtons {
  display: flex;
  justify-content: center;
  gap: 12px; /* Reduced gap */
  margin-top: 30px; /* Reduced margin */
}

.mainContainer .navigationButtons .navButton {
  padding: 10px 20px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly reduced */
  cursor: pointer;
  background: var(--color-secondary);
  color: var(--color-primary);
  border: 2px solid var(--color-accent);
  border-radius: 4px;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-light);
}

.mainContainer .navigationButtons .navButton:hover {
  background: var(--color-muted);
  transform: translateY(-2px); /* Slightly reduced */
  box-shadow: var(--shadow-deep);
}

.mainContainer .navigationButtons .navButton:active {
  transform: translateY(0);
  box-shadow: var(--shadow-light);
}

/* Line Items - Business Cards */
/* src/pages/MainPage.css */

.mainContainer .businessCards {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Reduced gap */
  margin-top: 25px; /* Reduced margin */
  /* Optional: Center the cards */
  align-items: center;
}

.mainContainer .businessCard {
  background: rgba(255, 255, 255, 0.15); /* Translucent white background */
  color: #f0f0f0; /* Off-white text for better contrast */
  padding: 16px; /* Reduced padding */
  border-radius: 12px; /* Increased radius for a smoother look */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); /* Glassmorphic shadow */
  backdrop-filter: blur(8px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Semi-transparent border */
  text-align: left; /* Left-align text */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transitions */
}

/* Optional: Add a hover effect for interactivity */
.mainContainer .businessCard:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 12px 40px 0 rgba(31, 38, 135, 0.37); /* Enhanced shadow on hover */
}

.mainContainer .businessCard h3 {
  font-size: 1.2rem; /* Reduced from 1.4rem */
  margin-bottom: 8px; /* Reduced margin */
  color: #ffffff; /* White color for headings */
  font-weight: 600; /* Slightly bolder for emphasis */
}

.mainContainer .businessCard .cardDivider {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3); /* Semi-transparent divider */
  margin-bottom: 8px; /* Reduced margin */
}

.mainContainer .businessCard p {
  font-size: 0.95rem; /* Slightly reduced */
  color: rgba(255, 255, 255, 0.8); /* Off-white for paragraphs */
  line-height: 1.4; /* Improved readability */
}

/* Optional: Adjust the container background for better contrast */


/* Typewriter Animation */
.mainContainer .typewriter {
  border-right: 2px solid var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: caret 0.75s step-end infinite;
  color: var(--color-accent);
  font-weight: 500;
}

@keyframes caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-primary);
  }
}

/* Hover Effects for Typewriter Text */
.mainContainer .typewriter:hover {
  color: var(--color-accent);
  transition: color 0.3s ease;
  text-shadow: var(--glow);
}

/* Responsive Styles */

/* Medium Devices (Tablets, 1024px and up) */
@media (max-width: 1024px) {
  .mainContainer .contentInner {
    max-width: 700px; /* Further reduced max-width */
  }

  .mainContainer .heroLogo {
    width: 160px; /* Further reduced */
  }

  .mainContainer .contentInner h1 {
    font-size: 2.4rem; /* Further reduced */
  }

  .mainContainer .contentInner h2 {
    font-size: 2rem; /* Further reduced */
  }

  .mainContainer .contentInner h3 {
    font-size: 1.2rem; /* Further reduced */
  }

  .mainContainer .contentInner p {
    font-size: 0.95rem; /* Further reduced */
  }

  .mainContainer .actionButtons .actionButton,
  .mainContainer .navigationButtons .navButton {
    padding: 10px 18px; /* Slight adjustment */
    font-size: 0.9rem; /* Further reduced */
  }

  /* Adjust business cards for tablet */
  .mainContainer .businessCards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .mainContainer .businessCard {
    width: 45%;
  }
}

/* Small Devices (Mobile Phones, 768px and below) */
@media (max-width: 768px) {
  .mainContainer .contentInner {
    max-width: 600px; /* Further reduced max-width */
  }

  .mainContainer .heroLogo {
    width: 140px; /* Further reduced */
  }

  .mainContainer .contentInner h1 {
    font-size: 2rem; /* Further reduced */
  }

  .mainContainer .contentInner h2 {
    font-size: 1.6rem; /* Further reduced */
  }

  .mainContainer .contentInner h3 {
    font-size: 1.1rem; /* Further reduced */
  }

  .mainContainer .contentInner p {
    font-size: 0.9rem; /* Further reduced */
  }

  .mainContainer .actionButtons .actionButton,
  .mainContainer .navigationButtons .navButton {
    padding: 8px 16px; /* Further reduced */
    font-size: 0.85rem; /* Further reduced */
  }

  .mainContainer .contentInner input,
  .mainContainer .contentInner textarea {
    max-width: 280px; /* Slight adjustment */
  }

  /* Adjust business cards for mobile */
  .mainContainer .businessCard {
    width: 100%;
  }
}

/* Extra Small Devices (Small Mobile Phones, 480px and below) */
@media (max-width: 480px) {
  .mainContainer .contentInner {
    max-width: 90%;
    padding: 16px; /* Further reduced padding */
  }

  .mainContainer .heroLogo {
    width: 250px; /* Further reduced */
  }

  .mainContainer .contentInner h1 {
    font-size: 1.1rem; /* Further reduced */
  }

  .mainContainer .contentInner h2 {
    font-size: 1.4rem; /* Further reduced */
  }

  .mainContainer .contentInner h3 {
    font-size: 1rem; /* Further reduced */
  }

  .mainContainer .contentInner p {
    font-size: 0.85rem; /* Further reduced */
  }

  .mainContainer .actionButtons .actionButton,
  .mainContainer .navigationButtons .navButton {
    padding: 6px 12px; /* Further reduced */
    font-size: 0.8rem; /* Further reduced */
  }

  .mainContainer .contentInner input,
  .mainContainer .contentInner textarea {
    max-width: 100% /* Further reduced */
  }
}
