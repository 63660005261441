.servicesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 16px 30px; /* Reduced bottom padding */
    background-color: transparent;
    text-align: center;
    width: 100%; /* Full-width section */
  }
  
  @media (min-width: 1024px) {
    .servicesSection {
      max-width: 1200px; /* Expand the width for desktop */
      margin: 0 auto; /* Center section on larger screens */
    }
  }
  
  .servicesContainer {
    display: flex;
    justify-content: center;
    gap: 32px; /* Increase spacing between cards for desktop */
    flex-wrap: wrap;
    width: 100%; /* Ensure the container spans the section width */
  }
  
  .serviceCard {
    background: rgba(255, 255, 255, 0.15);
    color: #f0f0f0;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    cursor: pointer;
    user-select: none;
    width: calc(33.33% - 32px); /* Make cards occupy equal space on desktop */
    max-width: 300px; /* Set a limit for card width */
  }
  
  /* Default styles */
.serviceCard h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .serviceCard p {
    font-size: 1.2rem;
    color: #dcdcdc;
  }
  
  /* Mobile styles */
  @media screen and (max-width: 768px) {
    .serviceCard h3 {
      font-size: 1.1rem; /* Adjusted font size for mobile */
      margin-block-start: 0rem;
    }
  
    .serviceCard p {
      font-size: 0.9rem; /* Adjusted font size for mobile */
    }
  }
  
  .serviceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(31, 38, 135, 0.37);
    background: rgba(255, 255, 255, 0.25);
  }
  
  .serviceCard:active {
    transform: translateY(-2px);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .servicesContainer {
      gap: 16px; /* Reduce spacing between cards */
      justify-content: center;
    }
  
    .serviceCard {
      width: 90%; /* Allow cards to take up most of the screen */
      max-width: 400px;
      margin: 0 auto; /* Center the cards */
    }
  }
  
  @media (max-width: 480px) {
    .servicesContainer {
      flex-direction: column; /* Stack cards vertically */
      gap: 16px;
      align-items: center;
    }
  
    .serviceCard {
      width: 100%; /* Ensure cards span full width */
      max-width: 360px;
      padding: 10px;
    }
  }
  