/* Base Container & Typography */
.nbaaswContainer {
    text-align: center;
    padding: 20px;
    background-color: #000;
    color: #fff;
    min-height: 100vh;
    font-family: 'Microgamma', Arial, sans-serif;
  }
  
  /* Header */
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 150px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
  }
  
  .logo:hover {
    transform: scale(1.05);
  }
  
  .nbaaswContainer h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  /* Video Section */
  .videoWrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 20px;
  }
  
  .nbaVideo {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
  }
  
  /* Buttons */
  .viewContentButton,
  .backButton {
    padding: 12px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    background: #3f3f3f;
    color: #fff;
    border-radius: 5px;
    margin: 15px 5px;
    transition: background 0.3s ease;
    font-family: 'Microgamma', Arial, sans-serif;
  }
  
  .viewContentButton:hover,
  .backButton:hover {
    background: #2f2e2e;
  }
  
  /* Gallery Section */
  .gallerySection {
    width: 100%;
    padding: 20px;
    background: #000;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
    position: relative; /* Ensures loading overlay is confined */
    font-family: 'Microgamma', Arial, sans-serif;
  }
  
  /* Tab Navigation */
  .tabContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabButton {
    padding: 10px 20px;
    font-size: 1rem;
    background: #444;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 5px;
    margin: 0 5px;
    font-family: 'Microgamma', Arial, sans-serif;
  }
  
  .tabButton.active,
  .tabButton:hover {
    background: #453f3d;
  }
  
  /* Masonry Layout */
  .my-masonry-grid {
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Masonry Item Container with individual loading overlay */
  .masonry-item-container {
    position: relative;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .masonry-item-container img {
    width: 100%;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .masonry-item-container:hover img {
    transform: scale(1.05);
  }
  
  /* Loading overlay for individual photos */
  .photo-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  /* Simple spinner */
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Lightbox Modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  
  .modal-content.focused {
    background: transparent;
    box-shadow: none;
    border: none;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    width: 80vw;
    max-width: 800px;
    max-height: 90vh;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .modal-image:hover {
    transform: scale(1.05);
  }
  
  /* Overlay icons inside lightbox modal */
  .modal-overlay-icons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }
  
  .modal-icon-button {
    background: rgba(0, 0, 0, 0.6);
    border: none;
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.4rem;
    transition: background 0.3s ease;
  }
  
  .modal-icon-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  