/* src/pages/Boardroom.css */

/* Import the custom font if needed */
/* If the LandingPage.css already imports the font, you can omit this */

/* Scope all styles within the .boardroom class to prevent conflicts */
.boardroom {
  /* Apply the custom font to the Boardroom component */
  font-family: 'Microgamma', Arial, sans-serif;

  /* General Styles */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 1; /* Ensure content is above background elements */
  background-color: #000; /* Maintain the black background */
  color: #fff; /* White text color */
  overflow-y: auto;
  scroll-snap-type: y mandatory; /* Snap vertically to sections */
}

/* Header */
.boardroom header {
  text-align: center;
  margin-bottom: 40px;
  z-index: 3; /* Above background elements */
}

.boardroom .logo {
  width: 150px; /* Increased size for better visibility */
  height: auto;
  margin-bottom: 10px;
  cursor: pointer; /* Indicate it's clickable */
  transition: transform 0.3s ease;
}

.boardroom .logo:hover {
  transform: scale(1.05);
}

.boardroom h2 {
  font-size: 2rem;
  
  text-transform: uppercase;
  margin: 0;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(31, 38, 135, 0.6),
               0 0 30px rgba(31, 38, 135, 0.8);
  animation: boardroomGlowEffect 2s infinite alternate ease-in-out;
}

/* IG Grid */
.boardroom .ig-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 15px;
  width: 100%;
  max-width: 1200px; /* Increased max-width for better spacing */
  margin: 0 auto;
  scroll-snap-align: start;
}

.boardroom .ig-grid-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #333;
  border-radius: 10px; /* More rounded corners */
  cursor: pointer;
  aspect-ratio: 1 / 1; /* Ensure square cells */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.05); /* Subtle background */
}

.boardroom .ig-grid-item:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 40px rgba(31, 38, 135, 0.37);
}

/* Image with Fallback */
.boardroom .ig-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the container without distortion */
  display: block;
}

/* Modal Overlay */
.boardroom .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Darker semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above all other elements */
}

/* Modal Content */
.boardroom .modal-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  color: #f0f0f0;
  padding: 0px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  max-width: 800px;
  text-align: center;
  animation: boardroomFadeInContent 0.5s ease-out forwards;
}

.boardroom .modal-content:focus {
  outline: none;
}

/* Carousel Image in Modal */
.boardroom .modal-image {
  width: 80vw;
  max-width: 800px;
  max-height: 90vh;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  object-fit: cover; /* Prevent cropping */
  transition: transform 0.3s ease;
}

/* Remove or comment out the carousel button rules if not needed */
/*
.boardroom .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  color: #f0f0f0;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  opacity: 0.7;
  transition: opacity 0.2s, background 0.2s;
  border-radius: 50%;
}

.boardroom .carousel-button:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.4);
}

.boardroom .carousel-button.left {
  left: 20px;
}

.boardroom .carousel-button.right {
  right: 20px;
}
*/

/* Updated Modal Styles to Focus on the Image */
/* Updated Modal Styles to Focus on the Image */
.boardroom .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Slightly transparent dark overlay */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Apply the backdrop blur */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  /* No flashing animation */
}

.boardroom .modal-content.focused {
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 40px 0; /* Extra vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.boardroom .modal-image {
  width: 80vw;
  max-width: 800px;
  max-height: 90vh;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Optionally remove the close-cross if not needed */
.boardroom .modal::after {
  display: none;
}


/* Carousel Buttons */



/* Close Modal on Click *

.boardroom .modal::after:hover {
  color: #ff5555;
}

/* Footer */
.boardroom footer {
  margin-top: 40px;
  text-align: center;
  font-size: 1rem;
  color: #666;
  z-index: 3; /* Above background elements */
}

/* Animations */

/* Glowing Text Effect - Namespaced for Boardroom */
@keyframes boardroomGlowEffect {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.273),
                 0 0 20px rgba(31, 38, 135, 0.345),
                 0 0 30px rgba(31, 38, 135, 0.252);
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.599),
                 0 0 30px rgba(42, 46, 99, 0.633),
                 0 0 40px rgba(81, 87, 169, 0.62);
  }
}

/* Flash Overlay for Modal - Namespaced for Boardroom */
@keyframes boardroomFlashAnimation {
  0% {
    background: transparent;
  }
  25% {
    background: rgba(52, 73, 94, 0.1); /* Navy Blue with low opacity */
  }
  50% {
    background: transparent;
  }
  75% {
    background: rgba(52, 73, 94, 0.1);
  }
  100% {
    background: transparent;
  }
}

/* Fade In Content - Namespaced for Boardroom */
@keyframes boardroomFadeInContent {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .boardroom .ig-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet */
    gap: 10px;
  }

  .boardroom .logo {
    width: 120px;
  }

  .boardroom h2 {
    font-size: 1.5rem;
  }

  .boardroom .carousel-button {
    font-size: 1.5rem;
    padding: 8px 12px;
  }

  .boardroom .modal-image {
    width: 95vw;
    max-width: 700px;
    max-height: 75vh;
  }
}

@media (max-width: 480px) {
  .boardroom .ig-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 column on mobile */
    gap: 8px;
  }

  .boardroom .carousel-button {
    font-size: 1.2rem;
    padding: 6px 10px;
  }

  .boardroom .modal-image {
    width: 95vw;
    max-width: 600px;
    max-height: 70vh;
  }

  .boardroom h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .boardroom .logo {
    width: 100px;
  }
}

